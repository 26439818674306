import { Box, Button, P } from '@mentimeter/ragnar-ui';
import React from 'react';
import { Wide, Section } from 'src/ui/layout';
import { H2 } from 'src/ui/typography';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import NextImage from 'src/components/Image/NextImage';
import Tag from 'src/components/Tag';

export interface HalfColorBlockProps {
  image: { src: string; alt: string };
  reverse: boolean;
  info: {
    tag: string;
    title: string;
    author: {
      name: string;
      role: string;
    };
    cta: {
      text: string;
      url: string;
    };
  };
}
const HalfColorBlock = ({ image, reverse, info }: HalfColorBlockProps) => {
  return (
    <Section px={[4, 4, 4, 6]}>
      <Wide
        flexDirection={[
          'column-reverse',
          'column-reverse',
          reverse ? 'row' : 'row-reverse',
        ]}
        alignItems={['center', 'center', 'stretch']}
      >
        <Box flex="1 1 100%" alignItems="center">
          <Box
            justifyContent={['center', 'center', 'space-around']}
            height="100%"
            gap={4}
            maxWidth={['600px', '600px', '400px']}
            mt={['-space5', '-space5', 0]}
            px={[3, 3, 0]}
            zIndex={1}
          >
            <Tag>{info.tag}</Tag>
            <H2
              fontWeight="regular"
              fontSize={[3, 3, 5]}
              lineHeight={['200', '200', '250']}
            >
              {info.title}
            </H2>
            <Box>
              <P fontSize={1}>{info.author.name}</P>
              <P fontSize={1}>{info.author.role}</P>
            </Box>
            <Button variant="outline" href={info.cta.url}>
              {info.cta.text}
            </Button>
          </Box>
        </Box>
        <Box flex="1 1 100%">
          <NextImage
            loader={contentfulImageLoader}
            src={image.src}
            alt={image.alt}
            width={600}
            height={600}
          />
        </Box>
      </Wide>
    </Section>
  );
};

export default HalfColorBlock;
