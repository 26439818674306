'use client';
import React, { type ReactNode } from 'react';
import { Section, Wide } from 'src/ui/layout';
import { H2, Subheading } from 'src/ui/typography';
import { Box } from '@mentimeter/ragnar-ui';
import Tag from './Tag';
import Card from './Card';

interface CardsBlockProps {
  tag: string;
  title: string;
  subtitle: string;
  cards: {
    text: {
      title: string;
      subtitle: string;
      mobileIcon: ReactNode;
    };
    image: {
      src: string;
      alt: string;
    };
  }[];
}

function CardsBlock({ tag, title, subtitle, cards }: CardsBlockProps) {
  return (
    <Section px={[4, 4, 4, 6]}>
      <Wide alignItems="center">
        <Tag>{tag}</Tag>
        <H2
          fontSize={[5, 7, 8]}
          fontWeight="regular"
          my="space6"
          textAlign="center"
        >
          {title}
        </H2>
        <Subheading
          fontWeight="regular"
          fontSize={[2, '112.5']}
          textAlign="center"
          mb="space12"
          maxWidth="800px"
        >
          {subtitle}
        </Subheading>
        <Box
          flexDirection={['column', 'column', 'row']}
          width="100%"
          gap={3}
          alignItems="stretch"
          px={[0, 5, 0]}
        >
          {cards?.map((card) => (
            <Card
              key={card.text.title + card.image.alt}
              text={card.text}
              image={card.image}
            />
          ))}
        </Box>
      </Wide>
    </Section>
  );
}

export default CardsBlock;
