import type { Document } from '@contentful/rich-text-types';
import { getContentfulPageProps } from 'src/cms/getContentfulPageProps';
import { PageRenderer } from 'src/cms/PageRenderer';
import Header from 'src/ui/header';
import type { IHeadFields } from 'types/generated/contentful';
import Hero from 'src/components/homepage/Hero';
import EditorExperienceBlock from 'src/components/homepage/EditorExperienceBlock';
import DesktopImageBlock from 'src/components/homepage/DesktopImageBlock';
import CardsBlock from 'src/components/CardsBlock';
import HalfColorBlock from 'src/components/homepage/HalfColorBlock';
import FullColorBlock from 'src/components/FullColorBlock';
import { useHomepageData } from 'src/components/homepage/useHomepageData';
import { getTranslationsProps } from 'src/utils/getServerSidePropsWithTranslation';
import Page from 'src/components/Page';

interface Props {
  document?: Document | undefined;
  head?: IHeadFields | undefined;
}

const HOME_PAGE_CONTENTFUL = '410CpG2kCSaBBbX2CPMRA5';

const HomePage = ({ document, head }: Props) => {
  const {
    heroData,
    desktopImageBlockData,
    cardsData,
    halfColorData,
    fullColorBlockData,
  } = useHomepageData();

  return (
    <Page
      headerElement={<Header showVotingBar />}
      title={head?.title}
      description={head?.description}
      image={head?.image?.fields?.file.url}
    >
      <Hero {...heroData} />
      <EditorExperienceBlock />
      <DesktopImageBlock {...desktopImageBlockData} />
      <CardsBlock {...cardsData} />
      {halfColorData.map((data, i) => (
        <HalfColorBlock key={data.info.title + i} {...data} />
      ))}
      <FullColorBlock {...fullColorBlockData} />
      {document && <PageRenderer document={document} />}
    </Page>
  );
};
export const getServerSideProps = getContentfulPageProps(
  HOME_PAGE_CONTENTFUL,
  getTranslationsProps,
);
export default HomePage;
