import { blackBase, gray100 } from '@mentimeter/ragnar-colors';
import { Box, P } from '@mentimeter/ragnar-ui';
import React from 'react';
interface TagProps {
  children: React.ReactNode;
}
const Tag = ({ children }: TagProps) => {
  return (
    <Box
      bg={gray100}
      p="10px 24px"
      justifyContent="center"
      alignItems="center"
      borderRadius="lg"
    >
      <P
        fontSize={2}
        fontWeight="semiBold"
        mb={0}
        color={blackBase}
        textAlign="center"
      >
        {children}
      </P>
    </Box>
  );
};

export default Tag;
