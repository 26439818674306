import type { AlignItemsProperty, Responsive } from '@mentimeter/ragnar-styled';
import { Box, type RagnarButtonState } from '@mentimeter/ragnar-ui';
import { gray1200 } from '@mentimeter/ragnar-colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from 'src/appState';
import { Action } from 'src/ui/actions/Action';
import { Section, Wide } from 'src/ui/layout';
import { H1, Meta, Subheading } from 'src/ui/typography';

interface HeroProps {
  title: string;
  subtitle: string;
  cta: string;
  cta_description: string;
}

interface ButtonData {
  href?: string;
  children?: string | undefined;
  description?: string;
  description_second_line?: string;
  alignItems?: Responsive<AlignItemsProperty> | undefined;
  state?: RagnarButtonState;
}

const Hero = ({ title, subtitle, cta, cta_description }: HeroProps) => {
  const [buttonData, setButtonData] = useState<ButtonData>({
    state: 'loading',
    children: 'Loading...',
  });
  const { user, userFinishedLoading } = useAppState();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!userFinishedLoading) return;
    if (user) {
      setButtonData({
        href: '/app',
        children: t('page_links.home'),
      });
    } else {
      setButtonData({
        href: '/auth/signup?referral=homepage',
        children: cta,
        alignItems: 'flex-start',
      });
    }
  }, [user, userFinishedLoading, cta, t]);
  return (
    <Section px={[4, 4, 4, 6]} py={0} pt={5}>
      <Wide justifyContent="center" alignItems="center">
        <H1
          fontSize={[5, 7, '70px']}
          fontWeight="regular"
          width="650px"
          textAlign="center"
          mb={3}
        >
          {title}
        </H1>
        <Subheading
          fontSize={[2, 3, '21px']}
          width={['270px', '540px']}
          textAlign="center"
          fontWeight="regular"
          color={gray1200}
          mb={3}
        >
          {subtitle}
        </Subheading>

        <Box alignItems="center">
          <Action {...buttonData} mb={3} variant="primary" size="large" />
          <Meta>{cta_description}</Meta>
        </Box>
      </Wide>
    </Section>
  );
};

export default Hero;
