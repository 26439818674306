'use client';
import { yellow100 } from '@mentimeter/ragnar-colors';
import { Box, Text, List, Button } from '@mentimeter/ragnar-ui';
import React, { type ReactNode } from 'react';
import { Section, Wide } from 'src/ui/layout';
import { H2, H4, Subheading } from 'src/ui/typography';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import { useMatch } from '@mentimeter/ragnar-device';
import NextImage from 'src/components/Image/NextImage';
import { ContactUs } from 'src/components/ContactUs';
interface FullColorBlockProps {
  strong: string;
  title: string;
  cta: string;
  subtitle: string;
  bullets: { text: string; icon: ReactNode }[];
  imageUrl?: string;
  backgroundColor?: string;
  ctaHref?: string;
  withModal?: boolean;
}
const FullColorBlock = ({
  strong,
  title,
  cta,
  subtitle,
  bullets,
  withModal,
  imageUrl = '//static.mentimeter.com/static/images/EnterpriseBlockBackground.png',
  ctaHref = '/enterprise',
  backgroundColor = yellow100,
}: FullColorBlockProps) => {
  const isDesktop = useMatch({ greaterThan: 2 });

  return (
    <Section px={[0, 0, 4, 6]}>
      <Wide>
        <Box
          flexDirection="row"
          bg={backgroundColor}
          width="100%"
          height={['474px', '474px', '542px']}
          borderRadius={[0, 0, '4xl']}
          justifyContent="center"
          p={[3, 3, 5]}
        >
          <Box
            maxWidth={['none', '550px', '420px']}
            justifyContent="space-around"
            height="100%"
            flex="0 1 100%"
            px={[4, 0]}
          >
            <H4 fontSize={1} lineHeight="100" mb={0}>
              {strong}
            </H4>
            <H2
              fontSize={[5, 5, 8]}
              fontWeight="regular"
              extend={() => ({
                lineHeight: '1 !important',
              })}
              mb={0}
            >
              {title}
            </H2>
            <Subheading fontSize={3}>{subtitle}</Subheading>
            <List
              extend={() => ({
                '> div li': {
                  listStyleType: 'none',
                },
              })}
            >
              {bullets?.map((bullet, index) => (
                <Box
                  as="li"
                  flexDirection="row"
                  gap={2}
                  key={bullet.text + index}
                >
                  <Box mt="1px">{bullet.icon}</Box>
                  <Text>{bullet.text}</Text>
                </Box>
              ))}
            </List>
            {withModal ? (
              <ContactUs variant="primary" size="default" text={cta} />
            ) : (
              <Button variant="primary" href={ctaHref}>
                {cta}
              </Button>
            )}
          </Box>
          {isDesktop && (
            <Box
              mt={-5}
              mr={-5}
              alignItems="flex-end"
              borderTopRightRadius="4xl"
              minHeight="542px"
              flex="1 1 100%"
            >
              <NextImage
                src={imageUrl}
                alt="Man and woman laughing"
                loader={contentfulImageLoader}
                width={532}
                height={542}
                style={{
                  borderTopRightRadius: '32px',
                  objectFit: 'cover',
                }}
              />
            </Box>
          )}
        </Box>
      </Wide>
    </Section>
  );
};

export default FullColorBlock;
